
import Component from 'vue-class-component';

import FilterItem from './FilterItem.vue';
import BaseFilter from './BaseFilter';

@Component({ components: { FilterItem } })
export default class SelectFilter extends BaseFilter {
  onChange(value: boolean) {
    this.notifyChange(value);
  }
}
